// External
import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Internal
import { SiteHeader as Root } from './SiteHeader.styled';
import useIsAuthenticated from '~hooks/useIsAuthenticated';
import useIntl from '~hooks/useIntl';
import SideMenu from '~components/layout/SideMenu';
import { getLocalePrefix } from '~lang/locales';
import { getNextLocale, parseLocale } from '~utils/helpers';
import { LocaleSwitcher } from '../LocaleSwitcher';
import {
  FormattedMessage,
  type FormattedMessageProps,
} from '~components/FormattedMessage';

import TaskrabbitLogo from '~public/next-images/taskrabbit-logo.svg';

// Types
export interface MenuItem {
  href: string;
  icon?: string;
  menu?: MenuItem[];
  subMenuHeader?: string;
  text: string | FormattedMessageProps;
  type?: string;
}
export interface MenuItems {
  logIn: MenuItem[];
  logOut: MenuItem[];
}

export interface SiteHeaderProps {
  LinkComp?: React.FunctionComponent<{
    children: React.ReactNode;
    className?: string;
    href: string;
  }>;
  menuItems?: MenuItems;
}

export const menuTextToElem = (text: MenuItem['text']) =>
  typeof text === 'string' ? text : <FormattedMessage {...text} />;

// Component
// TODO: Dynamic behavior (e.g. dropdown/sidebar menus)
const SiteHeader: FunctionComponent<SiteHeaderProps> = ({ menuItems }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const router = useRouter();
  const locale = getNextLocale(router);
  const { country } = parseLocale(locale);
  const intl = useIntl();
  const isAuthenticated = useIsAuthenticated();
  const selectedMenuItems = isAuthenticated
    ? menuItems?.logIn || []
    : menuItems?.logOut || [];

  const handleOpenSideMenu = () => setShowSideMenu(true);

  const handleCloseSideMenu = () => setShowSideMenu(false);

  return (
    <Root>
      <SideMenu
        items={selectedMenuItems}
        onClose={handleCloseSideMenu}
        open={showSideMenu}
      />
      <div className="header__container">
        <span
          aria-label="Menu"
          className="header__menu-toggle ss-icon ss-lnr-menu u-hidden--lg u-pull-left"
          onClick={handleOpenSideMenu}
        />
        {/*
            Temporarily using an <a> tag in order to avoid prerendering the NextJS Homepage
            https://github.com/taskrabbit/tr_web/pull/513
          */}
        <a
          className="header__logo js-header-logo js-taskrabbit-logo"
          href={getLocalePrefix(locale) || '/'}
        >
          <Image
            alt={intl.formatMessage({ id: 'book.header.logo' })}
            layout="fill"
            priority={true}
            src={TaskrabbitLogo}
          />
        </a>
        <div className="header__navigation-container u-hidden--sm u-hidden--md js-header-navigation--desktop">
          {selectedMenuItems.map((item) => {
            const aClass =
              item.type === 'button'
                ? 'btn btn-small btn-secondary u-hidden--sm u-hidden--md tasker-signup-btn'
                : 'header__navigation-link';

            return (
              <Link
                className={clsx(aClass, {
                  'header__navigation-active': router.pathname.includes(
                    item.href
                  ),
                })}
                href={item.href}
                key={typeof item.text === 'string' ? item.text : item.text.id}
                prefetch={false}
              >
                {menuTextToElem(item.text)}
              </Link>
            );
          })}
          {router.asPath === '/' && (country === 'CA' || country === 'ES') && (
            <LocaleSwitcher country={country} />
          )}
        </div>
      </div>
    </Root>
  );
};

export default SiteHeader;
